<template>
  <div class="home-page">
    <div class="hero">
      <h1 class="title">Make your photos amazing using AI</h1>
      <p class="subtitle">Easy to use AI tools to make your photos stand out</p>
    </div>

    <div class="buttons">
      <button class="button" @click="navigateToClothSwap">Undress Clothes</button>
      <button class="button" @click="navigateToFaceSwap">Face Swap</button>
      <button class="button" @click="navigateToPhotoEnhancer">Photo Enhancer</button>
      <button class="button" @click="navigateToPhotoRestore">Photo Restoration</button>
    </div>

    <!-- <div class="open-pook-ai">
      <p>Open Pook AI</p>
    </div> -->
  </div>
  <div>
    <ImageContent :items="items" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import { computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/index';

import { useHead } from '@vueuse/head'
const userStore = useUserStore();
const router = useRouter();
// 语言支持
const { t, locale } = useI18n();

// 控制放大图显示原图的弹窗
const ogUrl = ref("https://www.imagefaceswap.com/")
useHead({
  title: t('home_title'),
  htmlAttrs: {
    lang: locale.value,
  },

  meta: [
    { name: "robots", content: "index,follow" },
    { name: "googlebot", content: "index,follow" },
    { name: 'description', content: t('home_title') },
    { name: 'keywords', content: "undress ai,nude ai,clothoff,undress,remaker ai,face swap ai,remini,cutout pro,mejorar calidad de imagen,enhance,enhancer image,ai photo enhance,upscale,photo restoration,unblur" },
    { property: 'og:title', content: t('home_title') },
    { property: 'og:description', content: t('home_description') },
    { property: 'og:url', content: ogUrl.value },
    { name: 'twitter:title', content: t('home_title') },
    { name: 'twitter:description', content: t('home_description') },
    { name: 'twitter:url', content: ogUrl.value },
    { name: 'lang', content: locale.value }
  ],
  link: [

    { rel: 'alternate', hreflang: 'x-default', href: 'https://www.imagefaceswap.com' },
    { rel: 'alternate', hreflang: 'en', href: 'https://www.imagefaceswap.com/en' },
    { rel: 'alternate', hreflang: 'ar', href: 'https://www.imagefaceswap.com/ar' },
    { rel: 'alternate', hreflang: 'de', href: 'https://www.imagefaceswap.com/de' },
    { rel: 'alternate', hreflang: 'es', href: 'https://www.imagefaceswap.com/es' },
    { rel: 'alternate', hreflang: 'fr', href: 'https://www.imagefaceswap.com/fr' },
    { rel: 'alternate', hreflang: 'hi', href: 'https://www.imagefaceswap.com/hi' },
    { rel: 'alternate', hreflang: 'it', href: 'https://www.imagefaceswap.com/it' },
    { rel: 'alternate', hreflang: 'ja', href: 'https://www.imagefaceswap.com/ja' },
    { rel: 'alternate', hreflang: 'ko', href: 'https://www.imagefaceswap.com/ko' },
    { rel: 'alternate', hreflang: 'nl', href: 'https://www.imagefaceswap.com/nl' },
    { rel: 'alternate', hreflang: 'pt', href: 'https://www.imagefaceswap.com/pt' },
    { rel: 'alternate', hreflang: 'ru', href: 'https://www.imagefaceswap.com/ru' },
    { rel: 'alternate', hreflang: 'tr', href: 'https://www.imagefaceswap.com/tr' },
    { rel: 'alternate', hreflang: 'vi', href: 'https://www.imagefaceswap.com/vi' },
    { rel: 'alternate', hreflang: 'zh-CN', href: 'https://www.imagefaceswap.com/zhCN' },
    { rel: 'alternate', hreflang: 'zh-TW', href: 'https://www.imagefaceswap.com/zhTW' }
  ]

})
watchEffect(() => {
  // console.log(userStore.lang)
  const ogUrl = computed(() => `https://www.imagefaceswap.com/${userStore.lang}`)
  console.log("监听到变化enhancer=", userStore.showSubcrib)
  locale.value = userStore.lang
  useHead({
    title: t('home_title'),
    htmlAttrs: {
      lang: locale.value,
    },

    meta: [
      { name: "robots", content: "index,follow" },
      { name: "googlebot", content: "index,follow" },
      { name: 'description', content: t('home_description') },
      { name: 'keywords', content: "undress ai,nude ai,clothoff,undress,remaker ai,face swap ai,remini,cutout pro,mejorar calidad de imagen,enhance,enhancer image,ai photo enhance,upscale,photo restoration,unblur" },
      { property: 'og:title', content: t('home_title') },
      { property: 'og:description', content: t('home_description') },
      { property: 'og:url', content: ogUrl.value },
      { name: 'twitter:title', content: t('home_title') },
      { name: 'twitter:description', content: t('home_description') },
      { name: 'twitter:url', content: ogUrl.value },
      { name: 'lang', content: locale.value }
    ],
    link: [

      { rel: 'alternate', hreflang: 'x-default', href: 'https://www.imagefaceswap.com' },
      { rel: 'alternate', hreflang: 'en', href: 'https://www.imagefaceswap.com/en' },
      { rel: 'alternate', hreflang: 'ar', href: 'https://www.imagefaceswap.com/ar' },
      { rel: 'alternate', hreflang: 'de', href: 'https://www.imagefaceswap.com/de' },
      { rel: 'alternate', hreflang: 'es', href: 'https://www.imagefaceswap.com/es' },
      { rel: 'alternate', hreflang: 'fr', href: 'https://www.imagefaceswap.com/fr' },
      { rel: 'alternate', hreflang: 'hi', href: 'https://www.imagefaceswap.com/hi' },
      { rel: 'alternate', hreflang: 'it', href: 'https://www.imagefaceswap.com/it' },
      { rel: 'alternate', hreflang: 'ja', href: 'https://www.imagefaceswap.com/ja' },
      { rel: 'alternate', hreflang: 'ko', href: 'https://www.imagefaceswap.com/ko' },
      { rel: 'alternate', hreflang: 'nl', href: 'https://www.imagefaceswap.com/nl' },
      { rel: 'alternate', hreflang: 'pt', href: 'https://www.imagefaceswap.com/pt' },
      { rel: 'alternate', hreflang: 'ru', href: 'https://www.imagefaceswap.com/ru' },
      { rel: 'alternate', hreflang: 'tr', href: 'https://www.imagefaceswap.com/tr' },
      { rel: 'alternate', hreflang: 'vi', href: 'https://www.imagefaceswap.com/vi' },
      { rel: 'alternate', hreflang: 'zh-CN', href: 'https://www.imagefaceswap.com/zhCN' },
      { rel: 'alternate', hreflang: 'zh-TW', href: 'https://www.imagefaceswap.com/zhTW' }
    ]

  })
})

function navigateToFaceSwap() {
  router.push("/face-swap")
}

function navigateToClothSwap() {
  router.push("/cloth-swap")
}

function navigateToPhotoEnhancer() {
  router.push("/photo-enhancer")
}

function navigateToPhotoRestore() {
  router.push("/photo-restoration")
}

const items = ref([
  {
    after: "https://d2uvllf6n93h2s.cloudfront.net/2a0478e3-fefb-4b80-bc26-d966c93c42fc.webp",
    before: "https://d2uvllf6n93h2s.cloudfront.net/ebbd64df-df47-42cc-b3de-6ecd5ded82b6.webp",
    title: 'home_undress.title',
    text: 'home_undress.text'
  },
  {
    after: "https://d2uvllf6n93h2s.cloudfront.net/7082c0fc-871c-4bad-9943-356b7d420d37.webp",
    before: "https://d2uvllf6n93h2s.cloudfront.net/7b53f9fc-f099-450e-b495-cec13d41dbc7.webp",
    title: 'home_face_swap.title',
    text: 'home_face_swap.text'
  },
  {
    after: "https://d2uvllf6n93h2s.cloudfront.net/abc09048-b4d6-4b4c-a482-e12627d63175.webp",
    before: "https://d2uvllf6n93h2s.cloudfront.net/f2e48d01-30cb-4e3d-a5a7-524256dc1d2c.webp",
    title: 'home_enhancer.title',
    text: 'home_enhancer.text',
  },
  {
    after: "https://d2uvllf6n93h2s.cloudfront.net/6d7c157e-9802-49a2-9930-d071e9ff7744.webp",
    before: "https://d2uvllf6n93h2s.cloudfront.net/b377b55f-1859-4331-add2-457e6ee252bf.webp",
    title: 'home_restore.title',
    text: 'home_restore.text',
  },])
</script>
<style scoped>
.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef, #f8f9fa);
  color: #333;
  font-family: 'Helvetica Neue', sans-serif;
  text-align: center;
  padding: 20px;
}

.hero {
  margin-bottom: 40px;
}

.title {
  font-size: 2.5em;
  margin-bottom: 10px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.subtitle {
  font-size: 1.2em;
  opacity: 0.8;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.button {
  margin: 10px;
  padding: 15px 30px;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  background: #007bff;
  /* 蓝色 */
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.button:hover {
  background: #0056b3;
  /* 深蓝色 */
  transform: scale(1.05);
}

.button:nth-child(2) {
  background: #28a745;
  /* 绿色 */
}

.button:nth-child(2):hover {
  background: #1e7e34;
  /* 深绿色 */
}

.button:nth-child(3) {
  background: #dc3545;
  /* 红色 */
}

.button:nth-child(3):hover {
  background: #bd2130;
  /* 深红色 */
}

.button:nth-child(4) {
  background: #7d3df4;
  /* 红色 */
}

.button:nth-child(4):hover {
  background: #6d24f5;
  /* 深红色 */
}

.open-pook-ai {
  font-size: 1.5em;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1em;
  }

  .button {
    padding: 10px 20px;
    font-size: 1em;
  }
}
</style>