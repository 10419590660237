<template>
  <div>
    <h3 class="gradient-button" @click="handleReadMore">Read More</h3>
    <div class="news-list" id="news-list">
      <div v-for="news in randomNews" :key="news.url" class="news-item" @click="handleNewsClick(news.url)">
        <img :src="news.image" :alt="news.title" class="news-image" />
        <a :href="news.url" class="news-title" @click.prevent="handleNewsClick(news.url)">
          {{ news.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

// 新闻数据
const newsData = [
  { url: 'best-ai-boyfriend-tools.html', image: 'images/best-ai-boyfriend-generators-dreambf.png', title: '9 Best AI Boyfriend Apps & Sites in 2025 (Free & Paid): Your Guide to Virtual Romance' },
  { url: 'best-ai-face-swap-meme.html', image: 'images/face-swap-meme-with-donal-trump.jpg', title: '6 Best AI Face Swap Meme Tools & Apps in 2025: Unleash Your Creativity!' },
  { url: 'best-ai-face-swap-tools.html', image: 'images/faceswapper.ai-face-swap-testing.jpg', title: '8 Best AI Face Swap Tools in 2025: Revolutionize Your Photo and Video Editing' },
  { url: 'best-ai-headshot-generators.html', image: 'images/best-ai-headshot-generator-vidnoz.png', title: '11 Best AI Headshot Generators in 2025: Your Ultimate Guide' },
  { url: 'best-ai-professional-headshot-generator.html', image: 'images/artguru-ai-headshot-generator.png', title: '9 Best AI Professional Headshot Generators in 2025: Elevate Your Professional Image' },
  { url: 'best-cartoonizer-tools.html', image: 'images/best-cartoonizer-picsart.jpg', title: '5 Best Cartoonizer Tools in 2025' },
  { url: 'best-face-swap-app.html', image: 'images/pica-ai.png', title: 'Top 5 Face Swap Apps (AI-Powered) for Photo and Video in 2025: Unleash Your Creative Potential' },
  { url: 'best-face-swap-porn-tools.html', image: 'images/DeepSwap-Best-AI-Face-Swap-Porn-Overall.jpg', title: "2025's Premier Face Swap Porn Tools: A Definitive Guide" },
  { url: 'best-free-deepfake-porn-maker-online.html', image: 'images/ppnude-deepfake-porn-maker-online-free.png', title: '11 Free Deepfake Porn Maker Online Tools in 2025 (TESTED)' },
  { url: 'dopamine-girl-ai-generators.html', image: 'images/dopamine-girl-ai-generator-neural-love.png', title: '6 Best Dopamine Girl AI Generators and Face Swap Pron' },
  { url: 'how-to-add-a-face-to-a-picture.html', image: 'images/swapping-single-face-feature.png', title: 'How to Add a Face to a Picture for Free with Face Swap Pron Insights' },
  { url: 'how-to-create-disney-face-swap.html', image: 'images/elsa-characters-face-swap-with-repunzel.png', title: 'How to Create Disney Face Swaps with Face Swap Insights' },
  { url: 'how-to-create-mike-wazowski-face-swap.html', image: 'images/pet-switch-face-swap-pets.jpg', title: 'How to Create Mike Wazowski Face Swap' },
  { url: 'how-to-face-swap.html', image: './image/Create-Infinite-Faces-with-AI-Face-Swap-Technology.webp', title: 'What is Face-Swapping Technology?' }
];

// 随机选取6条新闻
function getRandomNews(newsArray, count) {
  const shuffled = newsArray.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}

const randomNews = ref(getRandomNews(newsData, 6));

// 处理新闻点击事件
function handleNewsClick(url) {
  window.open(url, '_blank');
}

// 处理 "Read More" 点击事件
function handleReadMore() {
  // 这里可以添加更多逻辑，例如加载更多新闻
}

// 页面加载完成后生成新闻列表
onMounted(() => {
  // 如果需要，可以在这里添加更多逻辑
});
</script>

<script>
export default {
  'name': 'otherNews'
}
</script>

<style scoped>
/* 新闻列表样式 */
.news-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.news-item {
  width: calc(33.33% - 20px);
  background-color: #f4f4f4;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.news-item img {
  width: 150px;
  height: 80px;
  object-fit: cover;
}

.tool-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 15px;
}

.news-item .news-title {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  text-decoration: none;
}

.news-item .news-title:hover {
  text-decoration: underline;
}

.gradient-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  text-align: center;
  text-decoration: none;
  background: linear-gradient(to right, #a9b4f1, #d037ee);
  /* 渐变背景 */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-right: 20px;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .news-list {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .news-item {
    width: 100%;
  }

  .news-item img {
    width: 100%;
    height: auto;
  }

  .news-item .news-title {
    font-size: 16px;
  }
}
</style>